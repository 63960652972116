<section *ngIf="!storeData.check">
  <div class="d-flex">
    <div *ngIf="haveLateralName">
      <p style="font-size: 16px; width: 100px;" class="fw-medium">Educação</p>
    </div>
    <div class="card-menu m-2 p-2 px-3 rounded">
      <app-card-link
      data-bs-toggle="tooltip"
        class="education-card"
        [title]="'Formação Wealth Planner'"
        title="Torne-se um Wealth Planner credenciado"
        [link]="
          'https://pg.empreenderdinheiro.com.br/wealth-planner/'
        "
        [imgPath]="'/assets/images/homeTF/formacaoWealth.svg'"
      >
      </app-card-link>
    </div> 
    <div class=" card-menu m-2 p-2 px-3 rounded">
      <app-card-link
      data-bs-toggle="tooltip"
        class="education-card"
        [title]="'Palestrante TechFinance®'"
        title="Torne-se um palestrante TechFinance®"
        [imgPath]="'/assets/images/homeTF/MicrophoneStage.svg'"
        [link]="
          'https://empreenderdinheiro.com.br/palestrante-techfinance/'
        "
      ></app-card-link>
    </div>
    <!-- <div class="card-menu m-2 p-2 px-3 rounded disabled">
      <app-card-nolink
      data-bs-toggle="tooltip"
        class="education-card"
        [title]="'MasterClasses'"
        title="
          Aulas gratuitas que valem um curso para acelerar seus resultados
        "
        [imgPath]="'/assets/images/homeTF/masterClass.svg'"
      >
      </app-card-nolink>
    </div> -->
    
  </div>
</section>

