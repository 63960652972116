<section>
  <div class="d-flex">
    <div *ngIf="haveLateralName">
      <p style="font-size: 16px; width: 100px" class="fw-medium">Ecossistema</p>
    </div>
    <div class="card-menu m-2 p-2 px-3 rounded">
      <app-card-link
        data-bs-toggle="tooltip"
        class="ecosystem-card"
        [title]="'ED Seguros'"
        title="Proteções impossíveis de ignorar"
        [imgPath]="'/assets/images/homeTF/logo-ed-seg.svg'"
        [tag]="'Disponível 18/12'"
        [link]="'https://pg.empreenderdinheiro.com.br/techfinance/ed-seguro/'"
      ></app-card-link>
    </div>
    <div class="card-menu m-2 p-2 px-3 rounded">
      <app-card-link
        class="ecosystem-card"
        data-bs-toggle="tooltip"
        [title]="'ED Capital'"
        title="Asset as a Service"
        [imgPath]="'/assets/images/homeTF/logo-ed-cap.svg'"
        [tag]="'Disponível 18/12'"
        [link]="'https://pg.empreenderdinheiro.com.br/techfinance/ed-capital/'"
      ></app-card-link>
    </div>
    <div class="card-menu m-2 p-2 px-3 rounded">
      <app-card-link
        data-bs-toggle="tooltip"
        class="ecosystem-card"
        [title]="'Hub de Crédito'"
        title="Taxas de juros menores, planejamentos melhores"
        [imgPath]="'/assets/images/homeTF/logo-hub.svg'"
        [link]="
          'https://pg.empreenderdinheiro.com.br/techfinance/hub-de-credito/'
        "
      ></app-card-link>
    </div>
    <!-- <div class="card-menu m-2 p-2 px-3 rounded">
      <app-card-link
        data-bs-toggle="tooltip"
        class="ecosystem-card"
        [title]="'Lvnt Inside Research'"
        title="Relatórios e Carteiras Recomendadas"
        [imgPath]="'/assets/images/homeTF/logo-lvnt.svg'"
        [link]="'https://empreenderdinheiro.com.br/relatorios-inside/'"
      ></app-card-link>
    </div> -->
    <div class="card-menu m-2 p-2 px-3 rounded">
      <app-card-link
        data-bs-toggle="tooltip"
        class="ecosystem-card"
        [title]="'CRM'"
        title="Gerencie o Relacionamento com seus Clientes"
        [imgPath]="'/assets/images/homeTF/logo-crm.svg'"
        [link]="
          'https://pg.empreenderdinheiro.com.br/techfinance/crm-planos-digital/'
        "
      ></app-card-link>
    </div>
    <div class="card-menu m-2 p-2 px-3 rounded">
      <app-card-link
        data-bs-toggle="tooltip"
        class="ecosystem-card"
        [title]="'Poupança Automática'"
        title="Guarde dinheiro sem perceber"
        [imgPath]="'/assets/images/homeTF/logo-oinc.svg'"
        [link]="
          'https://useoinc.com.br/?utm_source=techfinance-site&utm_medium=web&utm_campaign=partnership&utm_id=techfinance'
        "
      ></app-card-link>
    </div>
    <div class="card-menu m-2 p-2 px-3 rounded">
      <app-card-link
        data-bs-toggle="tooltip"
        class="ecosystem-card"
        [title]="'Sales Force Outsourcing'"
        title="Deixe que nós cuidamos das suas vendas"
        [imgPath]="'/assets/images/homeTF/logo-SFO.png'"
        [link]="
          'https://api.whatsapp.com/send?phone=558197195641&text=Sou%20assinante%20TechFinance%20e%20tenho%20interesse%20em%20saber%20mais%20sobre%20o%20SFO'
        "
      ></app-card-link>
    </div>
  </div>
</section>